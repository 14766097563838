<div *ngIf="contract" class="container-fluid" style="background: #e4f2f3;">
  <div class="row py-4 mt-4">
    <div class="file-wrapper text-left offset-md-1">
      <ul class="type-item bg-file-types-list ml-3">
        <b>File types:</b>
        <li *ngFor="let type of contract.file_types" class="ml-4">{{ type.value }};</li>
      </ul>
    </div>
    <div class="col-md-10 offset-md-1">
      <ngx-title-divider title="Required Contract Documents (SECTION C)" [bold]="true"></ngx-title-divider>
    </div>
    <div class="col-md-10 offset-md-1 mt-2" style="max-height: 300px; overflow-y: scroll;">
      <div class="edi-lite-item-container">
        <div class="edi-lite-item-content">
          <div class="row">
            <div class="col-md-12" *ngIf="isCH() && currentUser.role != 'User'">
              <p><a  target="_blank" [href]="getCognitoFormUrl()">1. 2024 CHPP Interim Report form</a></p>
              <p><a target="_blank" [href]="getCognitoFormCHPPGrantURL()">2. CHPP Grant Final Report</a></p>
              <p><a target="_blank" [href]="getCognitoFormLAPGrantURL()">3. LAP Grant Final Report</a></p>
            </div>
            <div class="col-md-12">
              <div class="actions">
                <div class="m-0" *ngIf="viewType === 'supplier' && currentUser.role != 'User'">
                  <button
                    class="btn btn-success p-0 text-capitalize"
                    (click)="addSubmittedDocumentFileEventEmitter.emit()"
                  >
                    <!-- [disabled]="true" -->
                    <i class="fa fa-plus"></i>
                    Upload Document
                  </button>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <ngx-po-files
            #filesSimplePO
            [companyType]="viewType"
            [hideAddButton]="true"
            [isApprovalSection]="true"
            [addFileEventEmitter]="addSubmittedDocumentFileEventEmitter"
            [enableControls]="viewType === 'supplier'"
            [purchaseOrderId]="contract?.id"
            [fileTypes]="contract.file_types"
            [defaultTag]="'$contract_documents_c'"
            [isApproveLogicEnabled]="true"
            [isInsuranceLogicEnabled]="true"
            [isListOfTypesDisplaying]="true"
            [isFileDeletionAvaliable]="viewType == 'supplier'"
          ></ngx-po-files>
        </div>
      </div>
    </div>
  </div>
</div>
